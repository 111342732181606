import React from "react";
import NavLogo from "./components/NavLogo";
import NavItems from "./components/NavItems";

const Navbar = () => {
  return (
    <div className="w-1/6 md:flex hidden min-w-fit h-full flex-col bg-white p-6 border-r-[1px] border-[#EEF2F6] drop-shadow-xl">
      <NavLogo />
      <NavItems />
    </div>
  );
};

export default Navbar;
