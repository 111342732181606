import React, { useState } from "react";
import NavLogo from "../../App/components/Navbar/components/NavLogo";
import { toast } from "react-toastify";
import { userSignUpSchema } from "../../Helpers/validations";
import axios from "axios";
import envs from "../../Helpers/envs";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../State/Redux/features/user/userSlice";

const Signin = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleSignUp = async () => {
    try {
      await userSignUpSchema.validate(
        { name, email, password, confirmPassword },
        { abortEarly: false }
      );

      const res = await axios.post(
        `${envs.apiEndpoint}/auth/signup/email-pass`,
        {
          name,
          email,
          password,
        }
      );

      const token = res.data.token;
      const { name: userName, email: userEmail, id, aid } = jwtDecode(token);

      dispatch(setUser({ name: userName, email: userEmail, id, token, aid }));
      toast.success("Sign up successful");
    } catch (error) {
      if (error.inner) {
        error.inner.forEach((e) => {
          toast.error(e.message);
        });
      } else if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Sign up failed");
      }
    }
  };
  return (
    <div className="flex w-full h-full items-center justify-center">
      <div className="border border-[#E2E8F0] text-gray-700 text-lg bg-white p-14 rounded-xl flex flex-col items-center justify-center w-fit">
        <NavLogo />
        <input
          className="border border-gray-400 rounded-md p-2 mb-3 w-full"
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        ></input>
        <input
          className="border border-gray-400 rounded-md p-2 mb-3 w-full"
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <input
          className="border border-gray-400 rounded-md p-2 mb-3 w-full"
          type="text"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></input>
        <input
          className="border border-gray-400 rounded-md p-2 mb-6 w-full"
          type="text"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        ></input>
        <button
          onClick={handleSignUp}
          className="border border-gray-400 rounded-md p-2 mb-6 hover:bg-blue-400 hover:text-white px-8 font-semibold"
        >
          Sign Up
        </button>
        <button
          className="text-sm font-bold text-gray-600 hover:text-sky-500"
          onClick={() => {
            navigate("/auth/signin");
          }}
        >
          Have an account? Sign In
        </button>
      </div>
    </div>
  );
};

export default Signin;
