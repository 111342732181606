const acquireVsCodeApi = window.acquireVsCodeApi;
// eslint-disable-next-line no-undef
const vscode = acquireVsCodeApi ? acquireVsCodeApi() : null;

window.platformState = {
  user: null,
};

export const attachPlatformListner = () => {
  try {
    window.addEventListener("message", (event) => {
      const message = event.data;

      switch (message.command) {
        case "getUserState":
          if (message.data.status === "success") {
            window.platformState.user = JSON.parse(message.data.payload);
          } else {
            window.platformState.user = null;
          }
          break;

        default:
          break;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getPlatformUserState = async () => {
  const buildType = process.env.REACT_APP_BUILD;

  if (buildType === "web") {
    const user = JSON.parse(localStorage.getItem("user"));
    return { ...user };
  } else if (buildType === "vsix") {
    attachPlatformListner();
    await vscode.postMessage({
      command: "getUserState",
    });
    // wait for response for response from vscode
    await new Promise((resolve) => setTimeout(resolve, 30));

    if (window.platformState.user === null) {
      return {};
    } else {
      return { ...window.platformState.user };
    }
  } else {
    console.log("WARNING: process.env.REACT_APP_BUILD is not set");
    return {};
  }
};

export const setPlatformUserState = async (state) => {
  const buildType = process.env.REACT_APP_BUILD;
  if (buildType === "web") {
    localStorage.setItem("user", JSON.stringify(state));
  } else {
    await vscode.postMessage({
      command: "setUserState",
      data: {
        payload: JSON.stringify(state),
        status: "success",
      },
    });
  }
};
