import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import ProtectedRoutes from "./components/routeProtection/ProtectedRoutes";
import PublicRoutes from "./components/routeProtection/PublicRoutes";
import Toast from "./components/Toast/Toast";
import Home from "../Pages/Home/Home";
import Error from "../Pages/Error/Error";
import Signin from "../Pages/Signin/Signin";
import Signup from "../Pages/Signup/Signup";
import Settings from "../Pages/Settings/Settings";
import Docs from "../Pages/Docs/Docs";
import Logs from "../Pages/Logs/Logs";
import { useSelector } from "react-redux";

function App() {
  return (
    <>
      <Toast />
      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<Home />} />
          <Route path="/logs" element={<Logs />} />
          <Route path="/docs" element={<Docs />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="*" element={<Error />} />
        </Route>
        <Route path="/auth" element={<PublicRoutes />}>
          <Route path="/auth/signin" element={<Signin />} />
          <Route path="/auth/signup" element={<Signup />} />
          <Route path="*" element={<Navigate to="/auth/signin" />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
