import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../State/Redux/features/user/userSlice";

const ProtectedRoutes = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const checkJwtToken = (jwtToken) => {
    var decoded = jwt_decode(jwtToken);

    if (decoded.exp < Date.now() / 1000) {
      dispatch(logout());
      return false;
    }
    return true;
  };

  const checkAuth = () => {
    if (user?.token) {
      return checkJwtToken(user.token);
    }

    return false;
  };

  const isAuth = checkAuth();

  return isAuth ? <Outlet /> : <Navigate to="/auth/signin" replace />;
};

export default ProtectedRoutes;
