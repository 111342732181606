import { HiOutlineDocumentText } from "react-icons/hi";
import { FaUserCog } from "react-icons/fa";
import { MdAttachMoney } from "react-icons/md";
import { FiSettings } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io";
import { RiSecurePaymentFill } from "react-icons/ri";
import { VscSettingsGear } from "react-icons/vsc";
import { VscHome } from "react-icons/vsc";
import { VscDebugDisconnect } from "react-icons/vsc";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const icons = Object.freeze({
  "/": <VscHome />,
  "/docs": <HiOutlineDocumentText />,
  "/logs": <RiSecurePaymentFill />,
  "/settings": <VscSettingsGear />,
});

const items = [
  {
    name: "Vaults",
    path: "/",
  },
  {
    name: "Docs",
    path: "/docs",
  },
  {
    name: "Logs",
    path: "/logs",
  },
];

const Item = ({ item, isActive }) => {
  const navigate = useNavigate();
  return (
    <button
      to={item.path}
      onClick={() => {
        navigate(item.path);
      }}
      className="flex items-center justify-start p-2 text-xl w-fit hover:bg-[#F8FAFC] rounded-lg "
      style={
        isActive
          ? {
              backgroundColor: "#F0F4FFaa",
              color: "#1E293B",
            }
          : {}
      }
    >
      <div className="text-3xl">{icons[item.path]}</div>
    </button>
  );
};

const NavItems = () => {
  const location = useLocation();

  return (
    <div className="flex flex-row items-center justify-between w-full h-full text-black/50">
      <div className="flex flex-row items-center justify-center w-full gap-2">
        {items.map((item, index) => {
          const isActive = location.pathname === item.path;
          return <Item key={index} item={item} isActive={isActive} />;
        })}
      </div>
      <Item
        item={{
          name: "Settings",
          path: "/settings",
        }}
        isActive={location.pathname === "/settings"}
      />
    </div>
  );
};

export default NavItems;
