import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import React, { useState } from "react";
import axios from "axios";
import envs from "../../../Helpers/envs";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateVault } from "../../../State/Redux/features/vaults/vaultSlice";
import { decryptPayload, encryptPayload } from "../../../Helpers/encryption";

const Env = ({ valueName, keyName, vault }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [isValueHidden, setIsValueHidden] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [newVaule, setNewValue] = useState(valueName);
  const [newKey, setNewKey] = useState(keyName);

  const deleteEnv = async () => {
    try {
      const token = user.token;
      const newEnvs = {
        ...vault.envs,
      };
      delete newEnvs[keyName];
      const payload = {
        envs: newEnvs,
      };
      const payloadEncrypted = encryptPayload(payload, user.aid);
      const res = await axios.put(
        `${envs.apiEndpoint}/vault/${vault._id}`,

        {
          payloadEncrypted,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      if (res.data.payloadEncrypted) {
        dispatch(
          updateVault(decryptPayload(res.data.payloadEncrypted, user.aid))
        );
      } else {
        toast.error("Failed to delete env");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete env");
    }
  };
  const updateEnv = async () => {
    try {
      const token = user.token;
      const newEnvs = {
        ...vault.envs,
      };
      delete newEnvs[keyName];
      newEnvs[newKey] = newVaule;
      const payload = {
        envs: newEnvs,
      };
      const payloadEncrypted = encryptPayload(payload, user.aid);
      const res = await axios.put(
        `${envs.apiEndpoint}/vault/${vault._id}`,

        {
          payloadEncrypted,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      if (res.data.payloadEncrypted) {
        dispatch(
          updateVault(decryptPayload(res.data.payloadEncrypted, user.aid))
        );
      } else {
        toast.error("Failed to update env");
      }
    } catch (error) {
      toast.error("Failed to update env");
    }
  };

  return (
    <div className="flex flex-row gap-2 items-center py-2 justify-between md:text-lg text-lg">
      {isEditing ? (
        <div className="flex gap-2 items-center">
          <input
            className="border border-gray-400 rounded-md p-2 w-full"
            type="text"
            placeholder="key"
            value={newKey}
            onChange={(e) => setNewKey(e.target.value)}
          ></input>
          <div>=</div>
          <input
            className="border border-gray-400 rounded-md p-2 w-full"
            type="text"
            placeholder="value"
            value={newVaule}
            onChange={(e) => setNewValue(e.target.value)}
          ></input>
        </div>
      ) : (
        <div className="flex gap-2 items-center">
          <div>{keyName}</div>
          <div>=</div>
          {isValueHidden ? (
            <div className="flex flex-row gap-2">
              <div>********</div>
            </div>
          ) : (
            <div>{valueName}</div>
          )}
        </div>
      )}
      {isEditing ? (
        <div className="flex md:gap-2 gap-1">
          <button
            onClick={() => {
              updateEnv();
            }}
            className="flex items-center justify-center px-2 py-1 text-lg  h-full bg-green-200 rounded-md w-fit text-black"
          >
            Save
          </button>
          <button
            onClick={() => {
              setIsEditing(!isEditing);
            }}
            className="flex items-center justify-center px-2 py-1 text-lg  h-full bg-orange-200 rounded-md w-fit text-black"
          >
            close
          </button>
        </div>
      ) : (
        <div className="flex md:gap-2 gap-1">
          <button
            onClick={() => {
              setIsValueHidden(!isValueHidden);
            }}
            className="flex items-center justify-center px-1 py-1 text-lg  h-full bg-gray-200 rounded-md w-fit text-black"
          >
            {!isValueHidden ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
          </button>
          <button
            onClick={() => {
              setIsEditing(!isEditing);
            }}
            className="flex items-center justify-center px-1 py-1 text-lg  h-full bg-gray-200 rounded-md w-fit text-black"
          >
            <AiOutlineEdit />
          </button>
          <button
            onClick={deleteEnv}
            className="flex items-center justify-center px-1 py-1 text-lg  h-full bg-red-200 rounded-md w-fit text-red-800"
          >
            <AiOutlineDelete />
          </button>
        </div>
      )}
    </div>
  );
};

export default Env;
