import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App/App";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import { store } from "./State/Redux/store";

let Router;
if (process.env.REACT_APP_BUILD === "web") {
  Router = BrowserRouter;
} else if (process.env.REACT_APP_BUILD === "vsix") {
  Router = MemoryRouter;
} else {
  Router = BrowserRouter;
  console.log("WARNING: process.env.REACT_APP_BUILD is not set");
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <Router initialEntries={["/"]} initialIndex={0} basename="/">
      <App />
    </Router>
  </Provider>
);
