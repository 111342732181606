import React from "react";
import Navbar from "../../App/components/Navbar/Navbar";
import MiniNavbar from "../../App/components/MiniNavbar/Navbar";

const Logs = () => {
  return (
    <div className="flex w-full h-full bg-[#E2E8F0]/10 ">
      <Navbar /> <MiniNavbar />
      <div className="w-full h-full flex items-center justify-center bg-[#E2E8F0]/10 p-6 no-scrollbar">
        <div className="bg-[#E2E8F0] text-black px-12 py-2 rounded-md w-fit">
          Comming Soon
        </div>
      </div>
    </div>
  );
};

export default Logs;
