import React, { useEffect } from "react";
import Navbar from "../../App/components/Navbar/Navbar";
import MiniNavbar from "../../App/components/MiniNavbar/Navbar";
import NodeDocs from "./components/NodeDocs";

const Docs = () => {
  useEffect(() => {}, []);

  const openDocs = () => {
    window.open(
      "https://docs.google.com/document/d/1srA_p7Vo3mJZtzZO6Rx5No7Dh5oL40r2dxEQ8tsD3g0/edit?usp=sharing",
      "_blank"
    );
  };

  return (
    <div className="flex w-full h-full">
      <Navbar />
      <MiniNavbar />
      <div className="w-full h-full overflow-x-scroll flex items-start justify-start md:bg-[#E2E8F0]/10 bg-white pt-24 px-2 md:p-6  no-scrollbar">
        <NodeDocs />
      </div>
    </div>
  );
};

export default Docs;
