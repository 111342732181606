const { createSlice } = require("@reduxjs/toolkit");

const initialState = [];

export const vaultSlice = createSlice({
  name: "vaults",
  initialState,
  reducers: {
    setVaults: (state, action) => {
      const vaults = action.payload;
      return vaults;
    },
    addVault: (state, action) => {
      const vault = action.payload;
      state.push(vault);
    },
    removeVault: (state, action) => {
      const vaultId = action.payload;
      return state.filter((vault) => vault._id !== vaultId);
    },
    updateVault: (state, action) => {
      const vaultData = action.payload;
      return state.map((vault) => {
        if (vault._id === vaultData._id) {
          return vaultData;
        }
        return vault;
      });
    },
  },
});

export const { setVaults, addVault, removeVault, updateVault } =
  vaultSlice.actions;

export default vaultSlice.reducer;
