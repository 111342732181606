import React from "react";
import Navbar from "../../App/components/Navbar/Navbar";
import envs from "../../Helpers/envs";
import axios from "axios";
import Vault from "./components/Vault";
import CreateVault from "./components/CreateVault";
import { toast } from "react-toastify";
import MiniNavbar from "../../App/components/MiniNavbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { setVaults } from "../../State/Redux/features/vaults/vaultSlice";

const Home = () => {
  const dispatch = useDispatch();
  const vaults = useSelector((state) => state.vaults);
  const user = useSelector((state) => state.user);

  const fetchVaultData = async () => {
    try {
      const token = user.token;
      const res = await axios.get(`${envs.apiEndpoint}/vault/`, {
        headers: {
          "x-access-token": token,
        },
      });
      if (res.status === 200) {
        dispatch(setVaults(res.data.vaults));
      } else toast.error("Failed to fetch vaults");
    } catch (error) {
      toast.error("Failed to fetch vaults");
      dispatch(setVaults([]));
    }
  };

  React.useEffect(() => {
    fetchVaultData();
  }, []);

  return (
    <div className="flex w-full h-full bg-[#E2E8F0]/10 ">
      <Navbar />
      <MiniNavbar />
      <div className="flex flex-col w-full h-full md:pt-0 pt-16 items-center md:gap-12 gap-4  overflow-y-scroll">
        <div className="w-full  md:p-6 p-4 no-scrollbar grid md:grid-cols-2 h-fit gap-8">
          {vaults?.map((vault) => {
            return <Vault key={vault._id} vault={vault} />;
          })}
        </div>{" "}
        <CreateVault />
      </div>
    </div>
  );
};

export default Home;
