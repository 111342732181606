import { createSlice } from "@reduxjs/toolkit";
import {
  getPlatformUserState,
  setPlatformUserState,
} from "../../../../Helpers/platformState";

const initialState = await getPlatformUserState();

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const user = action.payload;
      setPlatformUserState(user);
      return user;
    },
    logout: (state) => {
      setPlatformUserState({});
      return {};
    },
  },
});

export const { setUser, logout } = userSlice.actions;

export default userSlice.reducer;
