import React from "react";
import NavItems from "./components/NavItems";

const MiniNavbar = () => {
  return (
    <div className="md:hidden flex fixed top-0  w-full flex-row bg-gray-200 p-2 border-r-[1px] border-[#EEF2F6] drop-shadow-xl">
      <NavItems />
    </div>
  );
};

export default MiniNavbar;
