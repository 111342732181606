import React from "react";
import Env from "./Env";
import { configure } from "@testing-library/react";
import { toast } from "react-toastify";
import axios from "axios";
import envs from "../../../Helpers/envs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateVault,
  removeVault,
} from "../../../State/Redux/features/vaults/vaultSlice";
import { decryptPayload, encryptPayload } from "../../../Helpers/encryption";

const Vault = ({ vault }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [isVaultKeyHidden, setIsVaultKeyHidden] = React.useState(true);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [confirmRefreshKey, setConfirmRefreshKey] = React.useState(false);

  const deleteVault = async () => {
    try {
      const token = user.token;
      const res = await axios.delete(`${envs.apiEndpoint}/vault/${vault._id}`, {
        headers: {
          "x-access-token": token,
        },
      });

      if (res.status === 200) {
        dispatch(removeVault(vault._id));
      } else {
        toast.error("Failed to delete vault");
      }
    } catch (error) {
      toast.error("Failed to delete vault");
    }
  };

  const addNewEnv = async () => {
    try {
      const token = user.token;
      const randomString = Math.random()
        .toString(36)
        .substring(8)
        .toUpperCase();
      const key = `ENV_${randomString}`;
      const value = `VALUE_${randomString}`;

      const newEnvs = {
        ...vault.envs,
      };
      newEnvs[key] = value;
      const payload = {
        envs: newEnvs,
      };
      const payloadEncrypted = encryptPayload(payload, user.aid);
      const res = await axios.put(
        `${envs.apiEndpoint}/vault/${vault._id}`,

        {
          payloadEncrypted,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      if (res.data.payloadEncrypted) {
        dispatch(
          updateVault(decryptPayload(res.data.payloadEncrypted, user.aid))
        );
      } else {
        toast.error("Failed to add new env");
      }
    } catch (error) {
      toast.error("Failed to add new env");
    }
  };

  const refreshKey = async () => {
    try {
      const token = user.token;
      const res = await axios.get(
        `${envs.apiEndpoint}/vault/refreshKey/${vault._id}`,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      if (res.data.vault) {
        dispatch(updateVault(res.data.vault));
        setConfirmRefreshKey(false);
      } else {
        toast.error("Failed to refresh key");
      }
    } catch (error) {
      toast.error("Failed to refresh key");
    }
  };

  return (
    <div className="bg-gray-100 text-gray-800 rounded-xl md:p-8 p-4">
      <div className="flex flex-col justify-between pb-6 gap-2">
        <div className="text-2xl font-semibold">{vault.name}</div>
        <div className="flex gap-2">
          {isVaultKeyHidden ? (
            <button
              onClick={() => setIsVaultKeyHidden(!isVaultKeyHidden)}
              className="flex items-center justify-center px-2 py-1 bg-gray-200 rounded-md text-sm w-fit text-black"
            >
              Show Key
            </button>
          ) : (
            <div className="md:text-xl text-lg font-light">
              KEY : {isVaultKeyHidden ? "*********" : vault.key}
            </div>
          )}
          {confirmRefreshKey ? (
            <button
              onClick={refreshKey}
              className="flex items-center justify-center px-2 py-1 bg-orange-200 rounded-md text-sm w-fit text-black"
            >
              Confirm Refresh ?
            </button>
          ) : (
            <button
              onClick={() => {
                setConfirmRefreshKey(!confirmDelete);
                setTimeout(() => {
                  setConfirmRefreshKey(false);
                }, 2000);
              }}
              className="flex items-center justify-center px-2 py-1 bg-orange-200 rounded-md text-sm w-fit text-black"
            >
              Refresh Key
            </button>
          )}
          {confirmDelete ? (
            <button
              onClick={deleteVault}
              className="flex items-center justify-center px-2 py-1 bg-red-200 rounded-md text-sm w-fit text-red-800"
            >
              Confirm Delete ?
            </button>
          ) : (
            <button
              onClick={() => {
                setConfirmDelete(!confirmDelete);
                setTimeout(() => {
                  setConfirmDelete(false);
                }, 2000);
              }}
              className="flex items-center justify-center px-2 py-1 bg-red-200 rounded-md text-sm w-fit text-red-800"
            >
              Delete Vault
            </button>
          )}
        </div>
      </div>
      <hr className="w-full" />
      {vault["envs"] &&
        Object.entries(vault["envs"])?.length > 0 &&
        Object.entries(vault["envs"])?.map(([key, value]) => {
          return (
            <div key={key}>
              <Env valueName={value} keyName={key} vault={vault} />
              <hr className="w-full" />
            </div>
          );
        })}

      <button
        className="bg-[#E2E8F0] md:text-md text-lg p-1 rounded-md w-full  my-2 cursor-pointer hover:opacity-60"
        onClick={addNewEnv}
      >
        Add New Env
      </button>
    </div>
  );
};

export default Vault;
