import * as yup from "yup";

export const userSignUpSchema = yup.object().shape({
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
  email: yup.string().email("Invalid email").required("Email is required"),
  name: yup.string().required("Name is required"),
});

export const userSignInSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
  email: yup.string().email("Invalid email").required("Email is required"),
});
