import { MdContentCopy } from "react-icons/md";
import React from "react";
import { toast } from "react-toastify";
import { CodeBlock, CopyBlock } from "react-code-blocks";

const noteCode = `// Starting point of your application
const envvaults = require("envvaults");

async function main() {
    await envvaults.load();

    // Your application logic here
    const express = require("express");
    const mongoose = require("mongoose");
}

main();
`;

const NodeDocs = () => {
  const handleCopy = () => {
    navigator.clipboard.writeText("npm i @saweria/axios");
    toast.success("Copied to clipboard");
  };

  return (
    <div className="flex flex-col gap-8 w-full pb-14 opacity-100 text-black text-lg">
      <div className="flex flex-col gap-4 w-full">
        <div className="text-2xl font-semibold">
          How To Use inside NodeJS ?{" "}
          <span className="italic font-light text-red-800">
            Please read everything!
          </span>
        </div>
        <div className="flex gap-2 flex-col px-3">
          <div className="text-gray-500">Step 1</div>
          <div className="text-xl font-semibol italic">
            Install envvaults package from npm
          </div>
          <div className="flex justify-between items-center bg-gray-100 rounded-md">
            <code className="text-xl text-black font-light  w-full px-3 py-4">
              npm i envvaults
            </code>
            <button
              onClick={handleCopy}
              className=" rounded-md px-3 py-3 h-fit mr-4  hover:bg-blue-300 md:block hidden"
            >
              <MdContentCopy />
            </button>
          </div>
        </div>
        <div className="flex gap-2 flex-col px-3">
          <div className="text-gray-500">Step 2</div>
          <div className="text-xl font-semibol italic">
            Initialize envvaults using load() function
          </div>
          <div className="flex justify-between items-center bg-gray-100 rounded-md">
            <code className="text-xl text-black font-light  w-full px-3 py-4">
              const envvaults = require("envvaults");
              <br />
              await envvaults.load();
            </code>
            <button
              onClick={handleCopy}
              className="px-3 py-3 h-fit mr-4 rounded-md  hover:bg-blue-300 md:block hidden"
            >
              <MdContentCopy />
            </button>
          </div>
        </div>
        <div className="flex gap-2 flex-col px-3">
          <div className="text-red-500 font-semibold">NOTE</div>
          <div className="text-xl font-semibol ">
            Load envvaults before your application logic
          </div>
          <div className="flex justify-between items-center  text-xl bg-gray-100 rounded-md text-black">
            <CodeBlock
              text={noteCode}
              language={"javascript"}
              showLineNumbers={false}
              wrapLines
              theme={{
                textColor: "#000000",
                backgroundColor: "transparent",
              }}
            />
          </div>
        </div>
        <div className="flex gap-4 flex-col px-3">
          <div className="text-red-500 font-semibold">Requirements</div>
          <div className="text-xl font-semibol">
            envvaults.load() function looks for{" "}
            <span className="font-bold">EV_AID</span> and{" "}
            <span className="font-bold">EV_KEY</span> in enviroment. So we need
            to set them first.
          </div>
          <div className="text-xl font-semibold">
            How to set EV_AID and EV_KEY ?
          </div>
          <div className="flex gap-2 flex-col">
            <div className="text-xl">1. Set inside .env file</div>
            <div className="flex justify-between items-center bg-gray-100 rounded-md">
              <code className="text-xl font-light text-black  w-full px-3 py-4">
                EV_AID=xxx
                <br />
                EV_KEY=xxx
                <br />
                // other envs will be fetched from vault
              </code>
            </div>
          </div>
          <div className="flex gap-2 flex-col">
            <div className="text-xl">
              2. <span className="font-bold">OR</span> pass envs while building
              your app{" "}
            </div>
            <div className="flex flex-col  justify-between items-center bg-gray-100 rounded-md">
              <code className="text-xl font-light text-black  w-full px-3 py-4 pb-1">
                EV_AID=xxx EV_KEY=xxx npm start
              </code>
              <span className="font-bold">OR</span>
              <code className="text-xl font-light text-black  w-full px-3 py-4 pt-1">
                EV_AID=xxx EV_KEY=xxx node starting_point.js
              </code>
            </div>
          </div>
          <div className="flex gap-2 flex-col">
            <div className="text-xl">
              3. <span className="font-bold">OR</span> while building NodeJS
              docker container your app{" "}
            </div>
            <div className="flex justify-between items-center bg-gray-100 rounded-md">
              <code className="text-xl text-black font-light  w-full px-3 py-4">
                docker build -t -e EV_AID=xxx -e EV_KEY=xxx your_image_name_here
              </code>
            </div>
          </div>
          <div className="bg-red-200 p-3 mt-4 rounded-md text-2xl">
            Basically you need to set <span className="font-bold">EV_AID</span>{" "}
            and <span className="font-bold">EV_KEY</span> in your enviroment
            before starting NodeJS app. envvaults will fetch other envs from
            vault you have created.
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full gap-4">
        <div className="text-2xl font-semibold">
          Where can I find my EV_AID ?
        </div>
        <div className="italic text-xl">{`Settings > AID`}</div>
        <img
          src="https://drive.google.com/uc?export=view&id=1zpJnjv9PP8v8g8JHmTp_brvS94Mcm6wD"
          alt="aid"
          className="w-full rounded-xl max-w-[800px]"
        />
      </div>
      <div className="flex flex-col w-full gap-4">
        <div className="text-2xl font-semibold">
          Where can I find my EV_KEY ?
        </div>
        <div className="italic text-xl">{`Vaults > vault_name > show key`}</div>
        <img
          src="https://drive.google.com/uc?export=view&id=1EBkKOMjQLv_EF3tvV4bjR6Izhnx6ocip"
          alt="key"
          className="w-full rounded-xl max-w-[800px] "
        />
      </div>
    </div>
  );
};

export default NodeDocs;
