import CryptoJS from "crypto-js";

export const encryptPayload = (data, secretKey) => {
  const plaintext = JSON.stringify(data);
  const encryptedDataString = CryptoJS.AES.encrypt(
    plaintext,
    secretKey
  ).toString();
  return encryptedDataString;
};

export const decryptPayload = (data, secretKey) => {
  const decrypted = CryptoJS.AES.decrypt(data, secretKey);
  const decryptedDataString = decrypted.toString(CryptoJS.enc.Utf8);
  const decryptedDataJson = JSON.parse(decryptedDataString);
  return decryptedDataJson;
};
