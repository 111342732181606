import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import envs from "../../../Helpers/envs";
import { useDispatch, useSelector } from "react-redux";
import { addVault } from "../../../State/Redux/features/vaults/vaultSlice";
import CryptoJS from "crypto-js";
import { decryptPayload, encryptPayload } from "../../../Helpers/encryption";

const CreateVault = () => {
  const dispatch = useDispatch();
  const [vaultName, setVaultName] = React.useState("");
  const user = useSelector((state) => state.user);

  const createVault = async () => {
    try {
      const token = user.token;
      const payload = {
        name: vaultName,
      };
      const payloadEncrypted = encryptPayload(payload, user.aid);
      const res = await axios.post(
        `${envs.apiEndpoint}/vault/`,
        {
          payloadEncrypted,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      if (res.data.payloadEncrypted) {
        const newVault = decryptPayload(res.data.payloadEncrypted, user.aid);
        dispatch(addVault(newVault));
      } else {
        toast.error("Failed to create vault");
      }
    } catch (error) {
      toast.error("Failed to create vault");
    }
  };
  return (
    <div className="text-xl text-black   flex flex-col items-center justify-center md:gap-8 gap-4 md:w-1/2 w-full h-fit md:px-12 px-6 pb-12">
      <div className="md:text-3xl text-xl font-semibold text-center">
        Create New Vault
      </div>
      <div className="flex flex-row  w-full">
        <input
          className="border border-gray-400 rounded-md p-2 w-2/3"
          type="text"
          placeholder="New Vault Name"
          value={vaultName}
          onChange={(e) => setVaultName(e.target.value)}
        ></input>
        <button
          disabled={vaultName.length === 0}
          className="bg-[#E2E8F0] p-2 md:mx-12 mx-2 rounded-md w-1/3 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={createVault}
        >
          Create
        </button>
      </div>
    </div>
  );
};

export default CreateVault;
