import React from "react";
import Navbar from "../../App/components/Navbar/Navbar";
import MiniNavbar from "../../App/components/MiniNavbar/Navbar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../State/Redux/features/user/userSlice";
import { setVaults } from "../../State/Redux/features/vaults/vaultSlice";

const Settings = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [showAID, setShowAID] = React.useState(false);

  const logoutUser = () => {
    dispatch(setVaults([]));
    dispatch(logout());
  };

  return (
    <div className="flex w-full h-full">
      <Navbar />
      <MiniNavbar />
      <div className="flex w-full h-full items-center justify-center">
        {user && (
          <div className="bg-gray-100 text-gray-800 rounded-xl md:p-8 p-4 flex flex-col gap-2 text-xl items-start justify-center bg-[#E2E8F0]/10 no-scrollbar">
            <div className="flex gap-2">
              <div>username : </div>
              <div>{user.name}</div>
            </div>
            <div className="flex gap-2">
              <div>email : </div>
              <div>{user.email}</div>
            </div>
            <div className="flex gap-2">
              <div>AID : </div>
              {showAID ? (
                <div>{user.aid}</div>
              ) : (
                <div className="flex gap-2 text-center">******** </div>
              )}{" "}
              <button
                onClick={() => setShowAID(!showAID)}
                className="flex items-center justify-center px-2 py-1 bg-gray-600 rounded-md text-sm text-white"
              >
                {showAID ? "Hide" : "Show"}
              </button>
            </div>
            <div className="flex w-full items-center justify-center">
              <button
                className="bg-red-300 px-6 py-1 mt-12 rounded-md w-fit text-lg cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={logoutUser}
              >
                Log Out
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
